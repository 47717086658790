@import "vendor/bower_components/font-awesome/less/font-awesome";
@import "vendor/bower_components/bootstrap/less/bootstrap";
@import "vendor/bower_components/eonasdan-bootstrap-datetimepicker/src/less/bootstrap-datetimepicker-build";

// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// @import "font-awesome";
// @import "bootstrap";
// @import "bootstrap-datetimepicker-build";




.nb {
	white-space: nowrap;
	width: 1px;
}

.grey {
	color: #D8D8D8;
	a {
		color: #D8D8D8;;
	}
}

dt {
    font-weight: normal;
}

dd {
    font-weight: bold;
}

@media (min-width: @screen-sm-min) {
	.no-label {
		padding-top:25px;
	}
}







.process-block {
	a.disabled {
		display: none;
	}

	.alert {
	    margin-bottom: 10px;
	    margin-top: 10px;
	}
}


.film-block {
	background-color:#f5f5f5;
	border: 2px solid #ddd;
	text-align:center;
	overflow: hidden;
	height: 300px;
	width:300px;
	float:left;
	margin:5px;

	img {
		max-height: 300px;
		max-width: 300px;
	}

	&.active {
		border-color: #000;

	}
}

.bg-finished {
	background-color: #0f4f0b;
	color: #fff;
}

.bg-delivered {
	background-color: #c6efce;
	color: #0f4f0b;
}

.bg-stoped {
	background-color: #ff6f09;
	color: #9c0006;
}

.bg-error {
	background-color: #ffc7ce;
	color: #9c0006;
}